nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 950;
    min-height: 100px;
    background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);

    .menu-mobile-toggle-wrapper {
        z-index: 951;
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        padding: $spacing-base * 4;
        display: block;
        @include media-breakpoint-up(md) {
            display: none;
        }
        a {
            @include default-transition-all;
            color: white;
        }
    }

    .logo {
        position: fixed;
        top: 0;
        left: 50%;
        padding: $spacing-base * 5;
        transform: translateX(-50%);
        @include media-breakpoint-up(md) {

        }
        @include media-breakpoint-up(lg) {
            left: 0%;
            transform: translateX(0%);
        }
        img {
            width: 170px;
            @include media-breakpoint-up(md) {
                width: 200px;
            }
            @include media-breakpoint-up(lg) {
                width: 200px;
            }
        }
    }

    ul.menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 0px;
        padding-left: 0;
        margin-left: 0;
        @include default-transition-all;

        @include media-breakpoint-down(sm) {
            transform: translateX(-100%);
            &.active {
                transform: translateX(0%);
            }
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize(map-get($colors, primary-dark), 0.1);
            flex-direction: column;
        }

        /*Mobile*/

        @include media-breakpoint-up(md) {
            margin-top: $spacing-base * 18;
        }
        @include media-breakpoint-up(lg) {
            margin-top: $spacing-base * 6;
            margin-left: calc(10% + #{$spacing-base}*5);
        }

        .menu-logo {
            @include media-breakpoint-up(md) {
                display: none;
            }
            img {
                width: 200px;
                margin-bottom: $spacing-base * 8;
            }
        }

        li {
            position: relative;
            display: block;
            a {
                position: relative;
                @include default-transition-all;
                display: inline-block;
                text-decoration: none;
                padding: $spacing-base * 2 $spacing-base * 3 $spacing-base * 1.8 $spacing-base * 3;
                @include media-breakpoint-down(sm) {
                    margin-bottom: $spacing-base * 3;
                }
                font-family: $font-regular;
                text-transform: uppercase;
                color: white;
                box-sizing: border-box;
                // &.active {
                //     color: map-get($colors, primary-dark);
                //     background-color: white;
                //     border-radius: 50px;
                //     border: 2px solid map-get($colors, gray-lighten);
                //     font-weight: bold;
                // }
                // &:hover:not(.active) {
                //     color: map-get($colors, primary-lighten);
                // }
                border-radius: 50px;
                &:not(:hover) {
                    &::before {
                        content: " ";
                        position: absolute;
                        top: 0%;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 80%;
                        height: 100%;
                        border-bottom: 1px solid white;
                    }
                }

                &:hover {
                    color: map-get($colors, primary-dark);
                    background-color: white;
                    // border: 2px solid map-get($colors, gray-lighten);
                    filter: drop-shadow(0 0 4px map-get($colors, gray-lighten));
                    font-weight: bold;
                }
                // &:hover:not(.active) {
                //     color: map-get($colors, primary-lighten);
                // }
            }
        }
    }
}
