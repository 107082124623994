// Font
$font-size-base: 16px;

$font-regular: "Objectivity-Regular";
$font-light: "Objectivity-Light";
$font-bold: "Objectivity-Bold";

// Layout config
$spacing-base: 5px;

//Colors
$colors: (
    primary: #0C265B,
    primary-dark: #06132e,
    primary-light: #2B5072,
    primary-lighten: #4077aa,
    secondary: #ff6b00,
    secondary-dark: #b34b00,
    secondary-light: #ff974d,
    green: #0c4d23,
    green-dark: #05210f,
    green-light: #168f41,
    gray: #3c444d,
    gray-dark: #262b30,
    gray-light: #5e6a78,
    gray-lighten: #dadee2,
);


@each $key, $value in $colors {
    .background-#{$key} {
        background-color: $value !important;
        &:hover {
            background-color: white !important;
        }
    }
}