.btn {
    position: relative;
    padding: $spacing-base *3 $spacing-base *4 $spacing-base *3 $spacing-base *4;
    background-color: map-get($colors, primary-light);
    color: white;
    font-weight: bold;
    font-family: $font-bold;
    font-size: 0.9rem;
    line-height: 1rem;
    @include media-breakpoint-up(md) {
        font-size: 1.2rem;
    }
    box-sizing: border-box;
    border-radius: 50px;
    text-transform: uppercase;
    &:hover {
        color: map-get($colors, primary);
        background-color: white;
    }
}

.btn-feature {
    // &::after {
    //     content: " ";
    //     position: absolute;
    //     width: 137px;
    //     height: 55px;
    //     top: -65%;
    //     right: -10%;
    //     background-position: center;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     background-image: url(../img/gorrito.png);
    // }
}