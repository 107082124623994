.preloader {
    @include default-transition-all;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: map-get($colors, primary-dark);
    color: white;
    z-index: 999;
    img {
        max-width: 150px;
        margin-top: $spacing-base *5;
    }
    &.inactive {
        transform: translateY(-100%);
    }
}