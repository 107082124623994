@font-face {
    font-family: $font-regular;
    font-weight: normal;
    src: 
        url("../fonts/Objectivity-Regular.eot") format("eot"),
        url("../fonts/Objectivity-Regular.ttf") format("ttf"),
        url("../fonts/Objectivity-Regular.woff") format("woff"),
        url("../fonts/Objectivity-Regular.woff2") format("woff2");
}

@font-face {
    font-family: $font-light;
    font-weight: 100;
    src: 
        url("../fonts/Objectivity-Light.eot") format("eot"),
        url("../fonts/Objectivity-Light.ttf") format("ttf"),
        url("../fonts/Objectivity-Light.woff") format("woff"),
        url("../fonts/Objectivity-Light.woff2") format("woff2");
}

@font-face {
    font-family: $font-bold;
    font-weight: bold;
    src: 
        url("../fonts/Objectivity-Bold.eot") format("eot"),
        url("../fonts/Objectivity-Bold.ttf") format("ttf"),
        url("../fonts/Objectivity-Bold.woff") format("woff"),
        url("../fonts/Objectivity-Bold.woff2") format("woff2");
}