.phone-wrapper {
    position: fixed;
    bottom: $spacing-base * 8;
    right: 0;
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
    a {
        @include default-transition-all;
        padding: $spacing-base * 2 $spacing-base * 4 $spacing-base * 2 $spacing-base * 3;
        background-color: map-get($colors, secondary);
        color: white;
        border-radius: 50px 0px 0px 50px;
        font-size: 0.9rem;
        text-align: left;
        font-weight: bold;
        text-decoration: none;
        color: white;
        &:hover {
            background-color: white;
            color: map-get($colors, primary-dark);
        }
    }
}