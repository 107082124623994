.about-wrapper {
    margin-top: $spacing-base * 3;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, 1fr);
    }
    .about-img {
        display: flex;
        align-items: center;
        width: 100%;
        img {
            width: 100%;
        }
        // border-radius: $spacing-base * 2;
        background-color: rgba(0, 0, 0, 0.2);
        border: 8px solid rgba(0, 0, 0, 0);
    }
    .about-content {
        display: flex;
        align-items: center;
        width: 100%;
        padding: $spacing-base * 4;
        background-color: rgba(0, 0, 0, 0.2);
        p {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }
}