html {
    font-size: $font-size-base;
}
body {
    background-color: map-get($colors, primary);
    color: white;
}
h1, h2, h3, h4, h5 {
    position: relative;
    font-family: $font-bold;
    text-transform: uppercase;
}
h1 {
    font-size: 2.2rem;
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
    @include media-breakpoint-up(md) {
        font-size: 3.5rem;
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 3.5rem;
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
    }
}
h2 {
    font-size: 2.2rem;
    margin-top: 2.2rem;
    margin-bottom: 2.2rem;
}
h3 {
    font-size: 1.8rem;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
}
h4 {
    font-size: 1.6rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}
h5 {
    font-size: 1.2rem;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}
p {
    font-family: $font-light;
    font-size: 0.9rem;
}
.feature-title {
    width: 100%;
    &::before {
        content: attr(title);
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 1.2em;
        @include media-breakpoint-up(md) {
            font-size: 1.5em;
        }
        color: rgba(0, 0, 0, 0.5);
        font-weight: bold;
        letter-spacing: -0.1em;
        z-index: -1;
        width: 100%;
        text-align: left;
    }
}