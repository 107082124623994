.product-slider {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    @include default-transition-all;
    .product-slide {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include default-transition-all;
        min-width: 100%;
        // display: none;
        opacity: 0;
        height: 0vh;
        .slide-text {
            font-size: 1.2rem;
            font-family: $font-regular;
            max-width: 700px;
            margin-bottom: $spacing-base * 6;
        }
        &.active {
            opacity: 1;
            height: auto;
            // display: flex;
        }
        .slide-navigation {
            margin-top: $spacing-base * 3;
            .slide-arrow {
                @include default-transition-all;

                box-sizing: border-box;
                border: none;
                background-color: transparent;
                padding: $spacing-base *2 $spacing-base * 4;
                margin: auto;
                font-size: 1.4rem;
                color: white;
                border-radius: $spacing-base;
                &:hover {
                    text-decoration: none;
                    background-color: white;
                    color: map-get($colors, primary-light);
                }
                &:first-child {
                    margin-right: $spacing-base;
                }
                &:last-child {
                    margin-left: $spacing-base;
                }
            }
            .product-slide-back-button {
                @include default-transition-all;
                color: white;
                font-size: 1.2rem;
                padding: $spacing-base *2 $spacing-base * 2;
                @include media-breakpoint-up(md){
                    width: 350px;
                    padding: $spacing-base *2 $spacing-base * 4;
                    font-size: 1.4rem;
                }
                margin: auto;
                text-align: center;
                font-weight: bold;
                text-decoration: none;
                border-radius: $spacing-base;
                &:hover {
                    color: map-get($colors, primary-lighten);
                    background-color: white;
                }
            }
        }
    }
}