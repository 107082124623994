.proyectos {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    padding-top: $spacing-base*10;
    padding-bottom: $spacing-base*15;
    @include media-breakpoint-up(md){
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg){
        grid-template-columns: repeat(3, 1fr);
    }
    .proyecto {

        position: relative;
        
        // &::before {
        //     @include default-transition-all;
        //     content: "Ver más";
        //     position: absolute;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     font-family: $font-regular;
        //     font-size: 2rem;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     color: map-get($colors, primary-lighten);
        //     background-color: transparentize(map-get($colors, primary), 0.2);
        //     opacity: 0;
        //     pointer-events: none;
        //     z-index: 1;
        // }

        @include default-transition-all;
        // &:hover {
        //     transform: scale(0.95);
        //     &::before {
        //         opacity: 1;
        //     }
        // }

        // a {
        //     width: 100%;
        //     height: 100%;
        // }

        position: relative;
        overflow: hidden;

        .proyecto-image {
            width: 100%;
        }

        .proyecto-content {

            .proyecto-info-btn {
                background-color: white;
                padding: $spacing-base/2 $spacing-base*2 $spacing-base/2 $spacing-base*2;
                filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
                box-sizing: content-box;
                color: map-get($colors, primary);
                border-radius: $spacing-base;
                position: relative;
                margin-bottom: $spacing-base;
            }

            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: $spacing-base *3;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: flex-start;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+50,0.65+80,0.8+100 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.65) 80%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.65) 80%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.65) 80%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
            
            h2, h3, h4 {
                margin-top: 0.5rem;
                margin-bottom: 0.2rem;
                color: white;
            }

            p {
                margin-bottom: 0px;
                color: white;
            }

        }
    }
}