.contact-wrapper {
    width: 100%;
    margin-top: $spacing-base * 4;
    margin-bottom: $spacing-base * 4;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(1, 1fr);
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: 3fr 6fr 1fr;
    }
    .contact-info {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        background-color: map-get($colors, primary);
        padding: $spacing-base * 3 $spacing-base * 3 $spacing-base * 3 $spacing-base * 5;
        p {
            margin-top: $spacing-base*2;
            font-size: 1.1rem;
            a {
                @include default-transition-all;
                text-decoration: none;
                color: white;
                &:hover {
                    color: map-get($colors, primary-light);
                }
            }
        }
        i {
            color: map-get($colors, secondary);
        }
    }
    .contact-map {
        display: flex;
        min-height: 350px;
        .mapouter {
            width: 100%;
            .gmap_canvas {
                height: 100%;
            }
        }
    }
    .contact-social {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(lg) {
            flex-direction: column;
            justify-content: flex-end;
        }
        padding: $spacing-base * 3 $spacing-base * 2 $spacing-base * 3 $spacing-base * 2;
        background-color: rgba(0, 0, 0, 0.3);
        a {
            @include default-transition-all;
            padding: $spacing-base * 2;
            text-decoration: none;
            color: white;
            &:hover {
                color: map-get($colors, primary-light);
            }
        }
    }
}