@mixin default-transition-all {
    transition: all 0.6s ease-in-out;
}
@mixin background-with_blur($url){
    &::before {
        content: " ";
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url($url);
        filter: blur($spacing-base);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}