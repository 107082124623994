section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    .content-feature {
        position: relative;
        width: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 
            $spacing-base*18
            calc(5% + #{$spacing-base}*5) 
            $spacing-base*15
            calc(5% + #{$spacing-base}*5);
        @include media-breakpoint-up(md) {
            min-height: 100vh;
            padding: 
                $spacing-base
                calc(5% + #{$spacing-base}*5) 
                $spacing-base*5
                calc(5% + #{$spacing-base}*5);
        }
        @include media-breakpoint-up(lg) {
            min-height: 100vh;
            padding: 
                $spacing-base*8 
                calc(5% + #{$spacing-base}*5) 
                $spacing-base*5
                calc(5% + #{$spacing-base}*5);
        }
        &::before {
            content: " ";
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(12,38,91,0.5) 5%, rgba(12,38,91,1) 5%, rgba(12,38,91,1) 95%, rgba(12,38,91,0.5) 95%);
            transform: skew(3deg, 0deg);
        }

        video {
            width: 100%;
            padding-left: 0;
            padding-bottom: $spacing-base*6;
            padding-right: 0;
            @include media-breakpoint-up(md) {
                padding-left: $spacing-base*3;
                padding-right: $spacing-base*3;
            }
            @include media-breakpoint-up(lg) {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin: auto;
        width: 90%;
        min-height: 100vh;
        padding: 0 5% 0 5%;
        &.with-padding {
            padding: 0;
            @include media-breakpoint-up(md) {
                padding: 0 0 0 10%;
            }
            @include media-breakpoint-up(lg) {
                padding: 0 0 0 10%;
            }
        }
        &.fix-padding-tablets {
            padding-top: $spacing-base*18;
            @include media-breakpoint-up(md) {
                padding-top: $spacing-base*30 !important;
            }
            @include media-breakpoint-up(lg) {
                padding-top: $spacing-base*20 !important;
                padding-bottom: $spacing-base*12 !important;
            }
        }
        @include media-breakpoint-up(md) {
            width: 85%;
            min-height: 100vh;
        }
        @include media-breakpoint-up(lg) {
            width: 80%;
            min-height: 100vh;
        }
        &::before {
            content: " ";
            z-index: -1;
            position: absolute;
            top: 0;
            left: calc(-10%);
            width: 15%;
            min-width: 200px;
            height: 100%;
            box-sizing: border-box;
            background: 
                linear-gradient(88deg, rgba(0,0,0,0) 15%, rgba(0,0,0,0.5) 15%, rgba(0,0,0,0.3) 25%, rgba(0,0,0,0) 35%), 
                linear-gradient(88deg, rgba(0,0,0,0) 40%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 60%),
                linear-gradient(88deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0) 80%);
            @include media-breakpoint-up(md) {
                left: calc(-7% - 5%);
                width: 25%;
                background: 
                linear-gradient(87deg, rgba(0,0,0,0) 15%, rgba(0,0,0,0.5) 15%, rgba(0,0,0,0.3) 25%, rgba(0,0,0,0) 35%), 
                linear-gradient(87deg, rgba(0,0,0,0) 40%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 60%),
                linear-gradient(87deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.3) 80%, rgba(0,0,0,0) 90%);
            }
            @include media-breakpoint-up(lg) {
                left: calc(-10% - 5%);
                width: 35%;
                background: 
                linear-gradient(87deg, rgba(0,0,0,0) 15%, rgba(0,0,0,0.5) 15%, rgba(0,0,0,0.3) 25%, rgba(0,0,0,0) 35%), 
                linear-gradient(87deg, rgba(0,0,0,0) 40%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 60%),
                linear-gradient(87deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.3) 80%, rgba(0,0,0,0) 90%);
            }
            
        } 
            
    }
}

.background-1 {
    @include background-with-blur("../img/back_1.jpg");
}
.background-2 {
    @include background-with-blur("../img/back_2.jpg");
}
.background-3 {
    @include background-with-blur("../img/back_3.jpg");
}
.background-4 {
    @include background-with-blur("../img/back_4.jpg");
}
.background-5 {
    @include background-with-blur("../img/back_5.jpg");
}