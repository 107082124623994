.lightbox {
    @include default-transition-all;
    position: fixed;
    z-index: 955;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: $spacing-base*5;
    pointer-events: none;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    &.opened {
        pointer-events: all;
        opacity: 1;
    }
    .lightbox-image {
        max-width: 100%;
        max-height: 100%;
    }
    .lightbox-close {
        position: absolute;
        top: 0;
        right: 0;
        margin: $spacing-base*5;
        color: white;
    }
}