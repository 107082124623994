.products-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: $spacing-base * 4;
    column-gap: $spacing-base * 4;
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(4, 1fr);
    }
    .product {
        @include default-transition-all;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        padding: $spacing-base * 3;
        border-radius: $spacing-base * 2;
        &:hover {
            background-color: rgba(255, 255, 255, 0.3);

        }
        .product-img {
            width: 200px;
            height: 200px;
            @include media-breakpoint-up(md) {
                width: 200px;
                height: 200px;
            }
            @include media-breakpoint-up(lg) {
                width: 210px;
                height: 210px;
            }
            margin-bottom: $spacing-base*4;
            border-radius: 50%;
            border: 10px solid rgba(255, 255, 255, 0.2);
        }
        .product-title {
            text-transform: uppercase;
            font-size: 1.7rem;
            line-height: 1.9rem;
            font-weight: bold;
            color: white;
            text-align: center;
        }
    }
}